import { Component, OnInit, OnDestroy, inject, signal } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { Actions, Select, ofAction, ofActionSuccessful } from '@ngxs/store'
import { Observable, Subject, takeUntil } from 'rxjs'
import { Auth, AuthState } from './modules/auth/auth.state'
import { Title } from '@angular/platform-browser'
import { Json } from './services/database.types'
import { AppState } from './app.state'
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core'
import { TranslocoService } from '@jsverse/transloco'
import { Store } from '@ngxs/store'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  private readonly _locale = signal(inject<unknown>(MAT_DATE_LOCALE));
  private readonly _adapter = inject<DateAdapter<unknown, unknown>>(DateAdapter);

  @Select(AppState.customization)
  boardingCustomization$: Observable<Json | null>

  constructor(
    private actions: Actions,
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService,
    private store: Store,
  ) {
    this.boardingCustomization$.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((customization) => {
      const title = customization?.['app-title']
      const contextId = this.store.selectSnapshot(AppState.contextId)
      const favicon = `./assets/tmp/${contextId}/${customization?.['favicon']}`

      if (favicon) {
        const favIcon: HTMLLinkElement = document.querySelector('#favIcon')
        favIcon.href = favicon
      }

      if (title) {
        this.titleService.setTitle(title)
      }
    })
  }

  ngOnInit(): void {
    this.translocoService.langChanges$.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(lang => {
      this._locale.set(lang)
      this._adapter.setLocale(this._locale())
    })

    this.actions.pipe(
      ofActionSuccessful(Auth.Logout),
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      this.router.navigate(['/sign-out'])
    })
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
}
