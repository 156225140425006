import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Select, Store } from '@ngxs/store';
import { Auth, AuthState } from 'app/modules/auth/auth.state';
import { Observable, Subject } from 'rxjs';
import { TranslocoModule } from '@jsverse/transloco'

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [AsyncPipe, MatButtonModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule, TranslocoModule],
})
export class UserComponent implements OnInit, OnDestroy {
  @Input() showAvatar: boolean = false;
  userAvatar: string = 'assets/images/avatars/profile.jpg';
  userStatus = 'online';

  @Select(AuthState.username)
  username$: Observable<string>;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  updateUserStatus(status: string): void {

  }

  signOut(): void {
    this.store.dispatch(new Auth.Logout());
  }
}
