import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, makeEnvironmentProviders, APP_INITIALIZER } from '@angular/core';
import * as i1 from '@jsverse/transloco';
import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
const TRANSLOCO_PRELOAD_LANGUAGES = new InjectionToken('Languages to be preloaded');
class TranslocoPreloadLangsService {
  idleCallbackId;
  subscription = null;
  constructor(service, langs) {
    if (!langs.length) return;
    this.idleCallbackId = window.requestIdleCallback(() => {
      const preloads = langs.map(currentLangOrScope => {
        const lang = service._completeScopeWithLang(currentLangOrScope);
        return service.load(lang).pipe(tap(() => {
          if (!service.config.prodMode) {
            console.log(`%c 👁 Preloaded ${lang}`, 'background: #fff; color: #607D8B;');
          }
        }));
      });
      this.subscription = forkJoin(preloads).subscribe();
    });
  }
  ngOnDestroy() {
    if (this.idleCallbackId !== undefined) {
      window.cancelIdleCallback(this.idleCallbackId);
    }
    this.subscription?.unsubscribe();
    // Caretaker note: it's important to clean up references to subscriptions since they save the `next`
    // callback within its `destination` property, preventing classes from being GC'd.
    this.subscription = null;
  }
  static ɵfac = function TranslocoPreloadLangsService_Factory(t) {
    return new (t || TranslocoPreloadLangsService)(i0.ɵɵinject(i1.TranslocoService), i0.ɵɵinject(TRANSLOCO_PRELOAD_LANGUAGES));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TranslocoPreloadLangsService,
    factory: TranslocoPreloadLangsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslocoPreloadLangsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.TranslocoService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [TRANSLOCO_PRELOAD_LANGUAGES]
    }]
  }], null);
})();
window.requestIdleCallback = window.requestIdleCallback ?? function (cb) {
  const start = Date.now();
  return setTimeout(function () {
    cb({
      didTimeout: false,
      timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }, 1);
};
window.cancelIdleCallback = window.cancelIdleCallback ?? function (id) {
  clearTimeout(id);
};
// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}
function provideTranslocoPreloadLangs(langs) {
  return makeEnvironmentProviders([{
    provide: TRANSLOCO_PRELOAD_LANGUAGES,
    useValue: langs
  }, {
    provide: APP_INITIALIZER,
    useFactory: () => noop,
    multi: true,
    deps: [TranslocoPreloadLangsService]
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideTranslocoPreloadLangs };
