<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false"
    >
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/tmp/{{ contextId }}/logo.svg"
                    alt="Logo image"
                />
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div
    class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card" *transloco="let t"
>
    <!-- Header -->
    <div
        class="relative flex justify-center w-full overflow-hidden z-49 bg-[#002d72] print:hidden"
    >
        <div
            class="max-w-360 w-full sm:py-3 sm:m-8 sm:mb-0 md:mt-12 md:mx-8 md:pt-4 md:pb-3 sm:rounded-t-xl border-b sm:shadow-2xl overflow-hidden bg-card"
        >
            <!-- Top bar -->
            <div class="relative flex flex-auto items-center h-16 px-4 md:px-6">
                <!-- Logo -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="flex items-center mx-2">
                        <!-- Light version -->
                        <img
                            class="w-32 dark:hidden"
                            src="assets/tmp/{{ contextId }}/logo.svg"
                            alt="Logo image"
                        />
                        <!-- Dark version -->
                        <img
                            class="hidden dark:flex w-32"
                            src="assets/tmp/{{ contextId }}/logo.svg"
                            alt="Logo image"
                        />
                    </div>
                </ng-container>
                <!-- Navigation toggle button -->
                <ng-container *ngIf="isScreenSmall">
                    <button
                        mat-icon-button
                        (click)="toggleNavigation('mainNavigation')"
                    >
                        <mat-icon
                            [svgIcon]="'heroicons_outline:bars-3'"
                        ></mat-icon>
                    </button>
                </ng-container>
                <!-- Navigation -->
                <ng-container *ngIf="!isScreenSmall">
                    <div class="relative flex flex-0 items-center h-16 px-4">
                        <fuse-horizontal-navigation
                            [name]="'mainNavigation'"
                            [navigation]="navigation.horizontal"
                        ></fuse-horizontal-navigation>
                    </div>
                </ng-container>

                <!-- Components -->
                <div
                    class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2"
                >
                    <languages></languages>
                    <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <search [appearance]="'bar'"></search>
                    <shortcuts></shortcuts>
                    <messages></messages>
                    <notifications></notifications> -->

                    <a
                        *ngIf="(contextRole$ | async) === 'admin'"
                        class="fuse-horizontal-navigation-item"
                        [routerLink]="'/admin'"
                        [matTooltip]="t('home.admin-tip')"
                    >
                        <div
                            class="fuse-horizontal-navigation-item-title-wrapper"
                        >
                            <div class="fuse-horizontal-navigation-item-title">
                                <span> Admin </span>
                            </div>
                        </div>
                    </a>

                    <button
                        mat-icon-button
                        matTooltip="{{t('home.night-switch')}}"
                        (click)="toggleSccheme()"
                    >
                        <mat-icon
                            [svgIcon]="
                                (darkMode$ | async)
                                    ? 'heroicons_outline:sun'
                                    : 'heroicons_outline:moon'
                            "
                        ></mat-icon>
                    </button>
                    <user></user>
                </div>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:px-8">
        <div
            class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-xl sm:overflow-hidden bg-default"
        >
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="relative flex justify-center w-full z-49 print:hidden">
        <div
            class="flex items-center justify-between max-w-360 w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default"
        >
            <span class="font-medium text-secondary"
                >&copy; {{ currentYear }} - myLabel, {{t('home.mylabel-desc')}}</span
            >
            <div>
                <a
                    *ngIf="how"
                    class="cursor-pointer text-primary-600 underline font-bold"
                    (click)="openHowItWorks()"
                    >{{ t('home.how-does-it-work') }}</a
                >
            </div>
        </div>
    </div>

    <ng-template #howitworks>
        <div class="prose">
            <h1 mat-dialog-title class="text-primary-600">{{ t('home.how-does-it-work') }}</h1>
            <div mat-dialog-content class="prose">
                <markdown [data]="how" [disableSanitizer]="true"></markdown>
            </div>
        </div>
    </ng-template>
</div>
